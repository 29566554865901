import { watchImmediate } from '@vueuse/core';
import { AxiosError } from 'axios';
import isEmpty from 'lodash/isEmpty';
import { storeToRefs } from 'pinia';
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useTheme as useVuetifyTheme } from 'vuetify';

import { Locale, useLocale, useObservable, useTheme } from '@silae/composables';
import { useBackendHttpService } from '~/api';
import { useFeatures, useGuardedRoutes, useLocaleStorageSynchronization, useToasts } from '~/composables';
import { HomeRoute } from '~/pages/home/home.route';
import { getAppInsights, getPostHogInstance, updateLuxonConfiguration, updateVeeValidateConfig } from '~/plugins';
import { useLoadingService, useStorageService, useTrackingService } from '~/services';
import {
	Clearable,
	useAdministeredOffboardingEmployeesStore,
	useAuthenticationStore,
	useBackendAppStore,
	useCompanyContextStore,
	useCompanyInformationStore,
	useCompanySelectionStore,
	useEmployeeLoginsStore,
	useEmployeesStore,
	useInstitutionStore,
	useLeaveDaysCountersStore,
	useMeetingStore,
	useRolesStore,
	useVariableBonusStore,
	useVariableElementsValidationStore,
	useVariableHoursStore
} from '~/stores';
import { parseEnvVariable } from '~/utils';

import { SignInRoute } from './pages/sign-in/sign-in.route';

export function initBackend(): void {
	const { setBackendURL, setWithCredentials } = useBackendHttpService();

	setBackendURL(import.meta.env.MS_BACKEND_URL);
	setWithCredentials(true);
}

export function initSessionLostRedirection(): void {
	const { error: showErrorToast } = useToasts();
	const { t } = useI18n();
	const { push, currentRoute } = useRouter();
	const { responseInterceptors } = useBackendHttpService();
	const authenticationStore = useAuthenticationStore();

	responseInterceptors.use(
		response => response,
		(error: AxiosError) => {
			const isPublic = currentRoute.value?.matched.some(match => match.meta?.public);

			if (error.response && error.response.status === 401 && !isPublic && authenticationStore.isAuthenticated) {
				showErrorToast({
					title: t('common.feedback.error.title'),
					text: t('common.feedback.session_lost')
				});
				push(SignInRoute);
			}
			return Promise.reject(error);
		}
	);
}

export function initErrorInterceptors(): void {
	const { responseInterceptors } = useBackendHttpService();

	responseInterceptors.use(
		response => response,
		(error: AxiosError<ArrayBuffer>) => {
			if (error.response.config.responseType === 'arraybuffer') {
				error.response.config.responseType = 'json';
				error.response.data = decodeArrayBufferIntoJson(error);
			}
			return Promise.reject(error);
		}
	);
}

function decodeArrayBufferIntoJson(error: AxiosError<ArrayBuffer, any>) {
	return JSON.parse(new TextDecoder().decode(error.response.data));
}

export function initLoadingService(): void {
	const { requestInterceptors, responseInterceptors } = useBackendHttpService();
	const { addRunningCall, removeRunningCall } = useLoadingService();

	requestInterceptors.use(request => {
		addRunningCall();
		return request;
	});

	responseInterceptors.use(
		response => {
			removeRunningCall();
			return response;
		},
		(error: Error) => {
			removeRunningCall();
			return Promise.reject(error);
		}
	);
}

/***
 set storage ID on sign in
 */
export function initStorageService(): void {
	const { isAuthenticated, principal } = storeToRefs(useAuthenticationStore());
	const storageService = useStorageService();
	watchImmediate(isAuthenticated, isAuthenticated => {
		if (isAuthenticated) {
			storageService.setId(principal.value?.login);
		}
	});
}

const EMPLOYEE_SELECTION_KEY = 'employee-company-selection';
const MANAGER_SELECTION_KEY = 'manager-company-selection';
const ADMIN_SELECTION_KEY = 'admin-company-selection';
const ACTIVE_ROLE_KEY = 'active-role';

export function initLocalStorageSynchronization(): void {
	// selection
	const selectionStore = useCompanySelectionStore();
	const { employeeCompanyId, managerCompaniesIds, adminCompanyId } = storeToRefs(selectionStore);
	useLocaleStorageSynchronization(employeeCompanyId, EMPLOYEE_SELECTION_KEY, selectionStore.selectEmployeeCompany);
	useLocaleStorageSynchronization(managerCompaniesIds, MANAGER_SELECTION_KEY, selectionStore.selectManagerCompanies);
	useLocaleStorageSynchronization(adminCompanyId, ADMIN_SELECTION_KEY, selectionStore.selectAdminCompany);

	// role
	const rolesStore = useRolesStore();
	const { activeRole } = storeToRefs(rolesStore);
	useLocaleStorageSynchronization(activeRole, ACTIVE_ROLE_KEY, rolesStore.setActiveRole);
}

/***
 Check whether backend is up or not
 */
export function useHealthCheck(): void {
	const { trigger } = useObservable();
	trigger(useBackendAppStore().fetchCheckHealth$());
}

/***
 Ensure Vuetify theme is sync with app theme
 */
export function initThemeSync(): void {
	const { theme } = useTheme();
	const vuetifyTheme = useVuetifyTheme();
	watchImmediate(theme, theme => (vuetifyTheme.global.name.value = theme));
}

/***
 clear all necessary stores on sign out
 */
export function clearStateOnSignOut(): void {
	const authenticationStore = useAuthenticationStore();
	const { isAuthenticated } = storeToRefs(authenticationStore);
	const clearables: Clearable[] = [
		authenticationStore,
		useAdministeredOffboardingEmployeesStore(),
		useCompanyContextStore(),
		useCompanyInformationStore(),
		useEmployeeLoginsStore(),
		useEmployeesStore(),
		useMeetingStore(),
		useLeaveDaysCountersStore(),
		useStorageService(),
		useVariableBonusStore(),
		useVariableElementsValidationStore(),
		useVariableHoursStore(),
		useInstitutionStore()
	];

	watch(isAuthenticated, (isAuthenticated, wasAuthenticated) => {
		if (wasAuthenticated && !isAuthenticated) {
			clearables.forEach(store => store.clear());
		}
	});
}

export function initLocale(): void {
	const onLocaleUpdate = (locale: Locale) => {
		updateLuxonConfiguration({ locale: locale.value });
		updateVeeValidateConfig({ locale: locale.value });
	};

	useLocale(onLocaleUpdate);
}

/***
 Redirect to home page when user updates its role or company selection and finds herself on a forbidden route
 */
export function useForbiddenRouteRedirect() {
	const { isForbidden } = useGuardedRoutes();
	const { activeRole } = storeToRefs(useRolesStore());
	const { employeeCompanyId, adminCompanyId } = storeToRefs(useCompanySelectionStore());
	const { push, currentRoute } = useRouter();

	watchImmediate([employeeCompanyId, adminCompanyId, activeRole], ([newCompanyId, newAdminCompanyId, newRole]) => {
		if ((newCompanyId || newAdminCompanyId || newRole) && isForbidden(currentRoute.value)) push(HomeRoute);
	});
}

/***
 Set and clear App Insights user context on user sign-in and sign-out
 */
export function initUserTracking() {
	const authenticationStore = useAuthenticationStore();
	const { isAuthenticated, principal } = storeToRefs(authenticationStore);
	const { initAppInsights, initPostHog, setUserContext, clearUserContext } = useTrackingService();

	const enableAppInsights = parseEnvVariable(import.meta.env.MS_APP_INSIGHTS_ENABLED);
	if (enableAppInsights) {
		const appInsights = getAppInsights();
		initAppInsights(appInsights);
	}

	const enablePostHog = parseEnvVariable(import.meta.env.MS_POST_HOG_ENABLED);
	if (enablePostHog) {
		const hog = getPostHogInstance();
		initPostHog(hog);
	}

	watchImmediate(
		() => ({
			isAuthenticated: isAuthenticated.value,
			principal: principal.value
		}),
		({ isAuthenticated, principal }, former) => {
			if (isAuthenticated && !former?.isAuthenticated) {
				setUserContext(principal);
			}

			if (!isAuthenticated && former?.isAuthenticated) {
				clearUserContext();
			}
		}
	);
}

export function initTalentProvisioningUserStatus() {
	const meetingStore = useMeetingStore();
	const { trigger } = useObservable();
	const { selectedCompanyIds } = storeToRefs(useCompanySelectionStore());
	const { hasMeetingsLoginFeature } = useFeatures();

	watchImmediate(
		() => ({
			hasMeetingsLoginFeature: hasMeetingsLoginFeature.value,
			companyIds: selectedCompanyIds.value
		}),
		({ hasMeetingsLoginFeature, companyIds }) => {
			if (hasMeetingsLoginFeature && !isEmpty(companyIds)) {
				companyIds.forEach(companyId => trigger(meetingStore.fetchProvisioningUserStatus$(companyId, true)));
			}
		}
	);
}
