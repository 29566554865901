<template>
	<v-text-field
		v-model="model"
		class="search-input"
		:color="color"
		:density="density"
		:placeholder="$t('common.components.search_input.placeholder')"
		clearable
		hide-details
		rounded
		variant="outlined"
		@click:clear="clear"
	>
		<template #prepend-inner>
			<fa-icon color="secondary" icon="$search" size="sm" />
		</template>
	</v-text-field>
</template>

<style scoped>
.search-input {
	min-width: v-bind(minWidth);
}
</style>

<script lang="ts" setup>
import { VTextField } from 'vuetify/components';

import { useModelValue } from '@silae/composables';
import { UiDensity } from '@silae/types';

import { FaIcon } from '../../icons';

const props = defineProps({
  minWidth: { default: '210px' },
  modelValue: null,
  color: { default: 'secondary' },
  density: { default: UiDensity.COMFORTABLE }
});

const model = useModelValue(props);

const clear = () => (model.value = '');
</script>
