import { Role } from '../domain';

export const RouteKeys = {
	ACCOUNT: 'account',
	ACCOUNT_CONTRACT_DATA: 'account.contract-data',
	ACCOUNT_PREFERENCES: 'account.preferences',
	ACCOUNT_SECURITY: 'account.security',
	ADMIN_AGENDA: 'admin.agenda',
	ADMIN_DOCUMENTS: 'admin.documents',
	ADMIN_EMPLOYEE_CONTRACTS: 'admin.employee.details.contracts',
	ADMIN_EMPLOYEE_DETAILS: 'admin.employee.details',
	ADMIN_EMPLOYEE_INFORMATION: 'admin.employee.details.information',
	ADMIN_EMPLOYEE_LIFECYCLE: 'admin.employee.lifecycle',
	ADMIN_EMPLOYEE_LIST: 'admin.employee.list',
	ADMIN_EMPLOYEE_ONBOARDING: 'admin.employee.onboarding',
	ADMIN_EMPLOYEE_OFFBOARDING: 'admin.employee.offboarding',
	ADMIN_EMPLOYEE_PERSONAL_INFORMATION: 'admin.employee.details.personal_information',
	ADMIN_LEAVE_DAYS: 'admin-leave-days',
	ADMIN_PAYSLIPS: 'admin.payslips',
	ADMIN_AUTOMATIC_EARNED_WAGE_ACCESS: 'admin.automatic-earned_wage_access',
	ADMIN_EARNED_WAGE_ACCESS: 'admin.earned_wage_access',
	ADMIN_EMPLOYEE_MANAGEMENT: 'admin.employees',
	AGENDA: 'agenda',
	AUTOMATIC_EARNED_WAGE_ACCESSES: 'automatic-earned-wage-accesses',
	CONFIGURATION: 'configuration',
	CONFIGURATION_LOGINS: 'configuration.logins',
	CONFIGURATION_TEAMS: 'configuration.teams',
	CONNECTION: 'connection',
	GED: 'ged',
	GED_DOCUMENTS: 'ged.documents',
	GED_PAYSLIPS: 'ged.payslips',
	HOME: 'home',
	IMPERSONATION: 'impersonation',
	LEAVE_DAYS: 'leave-days',
	MEETINGS: 'meetings',
	NOT_FOUND: '404',
	PAYROLL: 'payroll',
	PAYROLL_DASHBOARD: 'payroll.dashboard',
	PUBLIC_DOWNLOAD: 'public-download',
	PUBLIC_DOWNLOAD_EXPIRED: 'public-download-expired',
	SALARY_TRANSFER: 'salary.transfer',
	SIGN_IN: 'sign-in',
	UPDATE_PASSWORD: 'update-password',
	UPDATE_PASSWORD_GENERATE_CODE: 'update-password.generate-code',
	UPDATE_PASSWORD_RESET: 'update-password.reset',
	UPDATE_PASSWORD_RESET_NO_PARAMETER: 'update-password.reset.np',
	VARIABLE_ELEMENTS: 'payroll.variable-elements',
	VARIABLE_ELEMENTS_EDITION: 'payroll.variable-elements.edition',
	VARIABLE_ELEMENTS_VALIDATION: 'payroll.variable-elements-validation',
	VARIABLE_ELEMENTS_VALIDATION_EARNED_WAGE_ACCESS: 'payroll.validation.earned-wage-access',
	VARIABLE_ELEMENTS_VALIDATION_LEAVE_DAYS: 'payroll.validation.leave-days',
	VARIABLE_ELEMENTS_VALIDATION_READONLY: 'payroll.variable-elements-validation.readonly',
	PAYSLIPS_VALIDATION_PENDING: 'payslips-validation.pending',
	PAYSLIPS_VALIDATION_COMPLETE: 'payslips-validation.complete'
};

export type RouteContext = {
	name: string;
	role: Role;
};

/**
 * this mapping allows to address our routes from emails or external links.
 * never update a key here as it's probably hard coded somewhere outside to point to one of our pages
 */
export const RouteContextByKey: { [key: string]: RouteContext } = {
	[RouteKeys.CONFIGURATION_LOGINS]: { name: RouteKeys.CONFIGURATION_LOGINS, role: Role.ADMIN }
};
