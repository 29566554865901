import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

export const ContractRoute: RouteRecordRaw = {
	name: RouteKeys.ACCOUNT_CONTRACT_DATA,
	path: 'contract',
	component: () => import('./contract-data.vue'),
	meta: {
		isForbidden: () => {
			const { hasEmployeeRole } = storeToRefs(useRolesStore());
			return computed(() => !hasEmployeeRole.value);
		},
		label: 'account.navigation.contract_data',
		icon: 'list-checks',
		requiresAuth: true,
		isSettingsNavEntry: true,
		order: 1
	}
};
